// Generated by Framer (7699a62)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useActiveVariantCallback, useConstant, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["VpcqGT9HZ", "uI2Fy4BQJ"];

const serializationHash = "framer-DsbMI"

const variantClassNames = {uI2Fy4BQJ: "framer-v-1xxmkq", VpcqGT9HZ: "framer-v-lzr6wy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "VpcqGT9HZ", "Variant 2": "uI2Fy4BQJ"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "VpcqGT9HZ"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const initialVariant = useConstant(() => variant)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "VpcqGT9HZ", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap14t0ay5 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("uI2Fy4BQJ")
})

const onTap1bp5u8j = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("VpcqGT9HZ")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Link href={{webPageId: "augiA20Il"}}><Image {...restProps} animate={variants} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 2300, intrinsicWidth: 2300, pixelHeight: 2300, pixelWidth: 2300, sizes: "min(66px, 100vw)", src: "https://framerusercontent.com/images/sa0P7zfI6hHGCsnssts3Ul7cOrM.png", srcSet: "https://framerusercontent.com/images/sa0P7zfI6hHGCsnssts3Ul7cOrM.png?scale-down-to=512 512w, https://framerusercontent.com/images/sa0P7zfI6hHGCsnssts3Ul7cOrM.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/sa0P7zfI6hHGCsnssts3Ul7cOrM.png?scale-down-to=2048 2048w, https://framerusercontent.com/images/sa0P7zfI6hHGCsnssts3Ul7cOrM.png 2300w"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-lzr6wy", className, classNames)} framer-17rlc48`} data-framer-name={"Variant 1"} data-highlight initial={initialVariant} layoutDependency={layoutDependency} layoutId={"VpcqGT9HZ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap14t0ay5} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({uI2Fy4BQJ: {"data-framer-name": "Variant 2", onTap: onTap1bp5u8j}}, baseVariant, gestureVariant)}/></Link></Transition>
</LayoutGroup>)

});

const css = [".framer-DsbMI[data-border=\"true\"]::after, .framer-DsbMI [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DsbMI.framer-17rlc48, .framer-DsbMI .framer-17rlc48 { display: block; }", ".framer-DsbMI.framer-lzr6wy { cursor: pointer; height: 66px; overflow: visible; position: relative; text-decoration: none; width: 66px; }", ".framer-DsbMI.framer-v-1xxmkq.framer-lzr6wy { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 66px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 66
 * @framerIntrinsicWidth 66
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"uI2Fy4BQJ":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerxe9zP_3mX: React.ComponentType<Props> = withCSS(Component, css, "framer-DsbMI") as typeof Component;
export default Framerxe9zP_3mX;

Framerxe9zP_3mX.displayName = "DALL_E_2024_02_20_03_20_1_3_";

Framerxe9zP_3mX.defaultProps = {height: 66, width: 66};

addPropertyControls(Framerxe9zP_3mX, {variant: {options: ["VpcqGT9HZ", "uI2Fy4BQJ"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerxe9zP_3mX, [])